import type { Contact } from 'features/BookingFormProxy/types';
import { Client as OldClient } from '../../../models/client.model';
import parsePhoneNumber from 'libphonenumber-js';
import { Client } from 'types/client';

export function requiredField(value: any, message?: string) {
  return value ? undefined : message || 'Required';
}

export function validateClient(
  value: Client | OldClient | undefined | null,
  isWithPhone: boolean,
  message?: string
) {
  return (
    // Если есть клиент (с телефоном) и у него непустая фамилия, то валидно.
    // Или есть контакт (без телефона) и у него непустая фамилия и не пустое имя, то валидно
    !(value && value.name?.trim()) && (message || 'Name fields are not valid')
  );
}

export const isRealClient = (
  client: Client | OldClient | Contact | null | undefined
) =>
  client 
  && Boolean((client as Client | OldClient).client_id || (client as Contact).id);

export function phoneValidInput(value: string, message?: string) {
  return value && parsePhoneNumber(value)?.isValid()
    ? undefined
    : message || 'Phone not valid format';
}
